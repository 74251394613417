export const FirebaseConfig = {
	"projectId": "wegaapp-car",
	"appId": "1:605341195380:web:e5e2a7b2699c68195a8521",
	"databaseURL": "https://wegaapp-car-default-rtdb.firebaseio.com",
	"storageBucket": "wegaapp-car.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCc5d42NEuAKXCbgiCZubkTQI2dSuVbzYE",
	"authDomain": "wegaapp-car.firebaseapp.com",
	"messagingSenderId": "605341195380",
	"measurementId": "G-YZYJTEXEL0"
};